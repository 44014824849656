import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { useInView } from "react-intersection-observer"
import ProductContainer from "../components/productContainer"
import descriptionData from "../content/description.json"
import buildSchema from "../script/buildschema"
import { Helmet } from "react-helmet"


const IndexPage = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  // const data = props.data.allAirtable.edges
  const {
    ref,
    inView: fixHeader,
    entry,
  } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })

  buildSchema(edges)


  return (
    <Layout>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.collectivetops.com/new-tops"
        />
        <title>{`New Tops | Collective Tops Dress`}</title>
        <meta name="description" content={descriptionData.all.pageDescription}/>        
      </Helmet>
      
      <div className="descriptions-container container px-12 mx-auto max-w-screen-xl">
        <h1 className="text-2xl mt-5 mb-5 font-bold">
          {descriptionData.all.heading}
        </h1>

        <p className="hidden md:block">
        At Collective Tops, we compile the latest and trendiest tops from a variety of sources, doing the shopping for you 
        so you don’t have to spend endless hours hopping from one store to another or browsing through 
        different boutiques to find the perfect top. Whether you're looking for a fashionable <Link to={"/tops-crop"}>Crop Tops</Link> for a party or a casual night out with your friends, rest assured that you’ll always be in vogue with our collection.
        We pride ourselves on our extensive, up-to-date selection of women's tops from various styles and trends. Ensuring our inventory remains fresh and in line with current trends, we weekly add and update our collection with new styles. This constant addition and refreshing of our tops selection provide you with an endless variety of fashionable pieces to choose from. At Collective Tops, we not only provide style and variety, but we also strive to make your shopping experience convenient and efficient.                            
        </p>
        <p className="text-l mt-5 mb-5 font-bold">
        Every Friday, Collective Tops, a leading fashion brand, releases a wide array of new top styles. This endeavor of ours serves to offer a constant, fresh assortment of trendy fashion pieces that our customers can sift through. Each weekly update introduces unique styles, stepping up the game in the fashion world and keeping our customer's wardrobes up-to-date. That's why we encourage you to visit our store or website on a weekly basis, ensuring that you don't miss out on the latest fashion trends to enhance your personal style statement.
        </p>
      </div>

      <div className="product-container grid grid-cols-2 onf md:grid-cols-3 max-w-screen-xl mx-auto">
        <ProductContainer edges={edges} path="new" dressType="New" />
      </div>

    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "tops_all" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
            product_images_2nd
            product_images_3rd
            product_images_4th
            product_images_5th
            product_images_6th
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.all.pageTitle}
    description={descriptionData.all.pageDescription}
  />
)

export default IndexPage
